import React from 'react'
import {Button, Popover, TextField, Typography} from "@material-ui/core"
import {Link} from "react-router-dom"
import SlackLogo from "../Assets/slack_logo_color.png"
import GoogleLogo from "../Assets/google_icon.png"

type LoginProps = {
  login: any,
  isLoggingIn:boolean, 
  currentAccountIsFetching:boolean,
  preferencesIsFetching:boolean, 
  preferences:any
}

const LoginMenu = ({login, isLoggingIn, currentAccountIsFetching, preferencesIsFetching, preferences}:LoginProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<any>(null);
  const [loginError, setLoginError] = React.useState(false)
  const [loginErrorMsg, setLoginErrorMsg] = React.useState("")
  const [loginForm , setLoginForm] = React.useState<any>( {
    email: "", 
    password: ""
  })

  const handlInputChange = (e:any)=> setLoginForm({
      [e.target.id]: e.target.value 
    })
  

  return (
    <>
       <Button
          style={{ flexShrink: 0, marginRight: 30}}
          disabled={isLoggingIn || currentAccountIsFetching || preferencesIsFetching || !preferences?.allowCookies}
          color="primary"
          onClick={e =>  setPopoverAnchorEl(e.currentTarget)}
          variant="outlined"
          >
          Sign In
        </Button>
          <Popover
            id="loginMenu"
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            onClose={()=> setPopoverAnchorEl(null) }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div className="loginPopover">
              <form onSubmit={e=>e.preventDefault()}>
                <TextField onChange={handlInputChange} error={loginError} className="menuElement" id="email" fullWidth label="Email" variant="outlined" />
                <TextField onChange={handlInputChange} error={loginError} className="menuElement" id="password" fullWidth label="Password" type="password" variant="outlined" />
                {loginError && <Typography variant="caption" style={{color:"#e74c3c"}}>{loginErrorMsg}</Typography>}
                <Button className="menuElement"
                  color="secondary"
                  variant="contained" 
                  onClick={
                    ()=>{
                      setLoginError(false)
                      console.log(loginForm)
                      setPopoverAnchorEl(null)
                      setLoginErrorMsg("")
                    }
                  }
                  disabled={isLoggingIn || currentAccountIsFetching || preferencesIsFetching || !preferences?.allowCookies}
                >
                  login
                </Button>
              </form>
              <Typography className="menuElement">or</Typography>
              <Button 
                variant="contained"
                fullWidth 
                onClick={()=>{
                  setPopoverAnchorEl(null)
                  setLoginError(false)
                  login() 
                }}
                style={{background: "#481449", color: "white"}}
                className="menuElement">
                  <img src={SlackLogo} alt=""/>
                  Login with slack
                </Button>
              <Button 
                variant="contained" 
                fullWidth
                onClick={()=>{
                  setPopoverAnchorEl(null)
                  login() 
                }}
                style={{background: "#E9523E", color: "white"}}
                className="menuElement">
                  <img src={GoogleLogo} alt=""/>
                  Login with google
                </Button>
              <Typography className="menuElement" variant="caption">Don't have an account? <Link onClick={()=> setPopoverAnchorEl(null)} style={{color: "#61CE70"}} to="/signup">Signup here</Link></Typography>
            </div>
          </Popover>
    </>
  )
}
export default LoginMenu
