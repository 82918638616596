import { createSchema as S, TsjsonParser, Validated } from 'ts-json-validator'

import Opportunity, { OpportunityId } from '../Domain/Opportunity'
import AccessToken from '../Domain/AccessToken'
import IOpportunitiesRepository from '../Domain/IOpportunitiesRepository'

export default class OpportunitiesRepository implements IOpportunitiesRepository {
  private apiBaseUrl(): URL {
    const url = new URL(process.env.REACT_APP_API_BASE!)
    if (!url.pathname.endsWith('/')) url.pathname += '/'
    return url
  }

  async list(): Promise<Array<{ id: OpportunityId, value: Opportunity }>> {
    const url = this.apiBaseUrl()
    url.pathname += 'helpful/opportunities'
    const response = await fetch(url.href)
    const json = await response.json()
    if (!listParser.validates(json)) {
      throw new Error('failed to parse opportunities response')
    }
    return json.map((item: Validated<typeof itemSchema>) => ({
      id: item.fields.ID,
      value: new Opportunity({
        title: item.fields.Title,
        description: item.fields.Description,
        skills: item.fields.Skills ?? null,
        openPositions: item.fields['Open Positions'],
        weeklyCommitment: item.fields['Weekly Commitment (hrs)'] ?? null,
      })
    }))
  }

  async appliedToByCurrentUser(accessToken: AccessToken): Promise<ReadonlySet<OpportunityId>> {
    const url = this.apiBaseUrl()
    url.pathname += 'helpful/volunteer'
    const response = await fetch(url.href, {
      headers: { Authorization: `Bearer ${accessToken.value}` }
    })
    const json = await response.json()
    const parser = new TsjsonParser(appliedToSchema)
    if (!response.ok) {
      throw new Error('appliedToByCurrentUser request failed')
    }
    if (!parser.validates(json)) {
      throw new Error('appliedToByCurrentUser failed validation')
    }
    return new Set(json[0].fields['Positions Applied For']?.split(',') ?? [])
  }

  async submitApplication({
    opportunityId,
    accessToken,
  }: {
    opportunityId: OpportunityId,
    accessToken: AccessToken,
  }): Promise<void> {
    const url = this.apiBaseUrl()
    url.pathname += 'helpful/opportunities/apply'
    url.searchParams.append('ID', opportunityId)
    const response = await fetch(url.href, {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken.value}` }
    })
    if (!response.ok) {
      throw new Error('failed to submit user application')
    }
  }
}

const appliedToSchema = S({
  type: 'array',
  maxItems: 1,
  items: S({
    type: 'object',
    required: ['fields'],
    properties: {
      fields: S({
        type: 'object',
        properties: {
          'Positions Applied For': S({ type: 'string' }),
        },
      }),
    },
  }),
})

const itemSchema = S({
  type: 'object',
  required: [ 'id', 'createdTime', 'fields' ],
  properties: {
    id: S({ type: 'string' }),
    createdTime: S({ type: 'string', format: 'date-time' }),
    fields: S({
      type: 'object',
      required: [
        'ID',
        'Description',
        'Priority',
        'Title',
        'Open Positions',
      ],
      properties: {
        ID: S({ type: 'string' }),
        Description: S({ type: 'string' }),
        Priority: S({ type: 'array', items: S({ type: 'string' }) }),
        Title: S({ type: 'string' }),
        Skills: S({ type: 'string' }),
        'Required Experience': S({ type: 'string' }),
        'Weekly Commitment (hrs)': S({ type: 'integer' }),
        'Other Titles': S({ type: 'string' }),
        'Open Positions': S({ type: 'integer' }),
      },
    })
  }
})

const listSchema = S({
  type: 'array', 
  items: itemSchema,
})

const listParser = new TsjsonParser(listSchema)





