import { Token } from 'typedi'
import AccessToken from './AccessToken'
import Volunteer from './Volunteer'

interface IVolunteersRepository {
  current(token: AccessToken): Promise<Volunteer | null>,
  getCountriesList(): Promise<any>
}

namespace IVolunteersRepository {
  export const token = new Token<IVolunteersRepository>('IVolunteersRepository')
}

export default IVolunteersRepository
