import { createSchema as S, TsjsonParser } from 'ts-json-validator'
import Volunteer from '../Domain/Volunteer'
import AccessToken from '../Domain/AccessToken'
import IVolunteersRepository from '../Domain/IVolunteersRepository'

export default class VolunteersRepository implements IVolunteersRepository {
  private apiBaseUrl(): URL {
    const url = new URL(process.env.REACT_APP_API_BASE!)
    if (!url.pathname.endsWith('/')) url.pathname += '/'
    return url
  }

  async current(token: AccessToken) {
    const url = this.apiBaseUrl()
    url.pathname += 'helpful/volunteer'

    const response = await fetch(url.href, {
      headers: { Authorization: `Bearer ${token.value}` }
    })

    if (!response.ok) {
      if (response.status === 401) return null
      else throw new Error('fetch failed for current user')
    }

    const json = await response.json()

    const parser = new TsjsonParser(currentVolunteerSchema)
    if (!parser.validates(json)) {
      throw new Error('invalid User response')
    }

    if (json.length === 0) return null

    return new Volunteer({
      weeklyCapacity: json[0].fields['Weekly Capacity'] ?
        parseWeeklyCapacity(json[0].fields['Weekly Capacity']) :
        null,
      timezone: json[0].fields['Timezone'] ?? 'UTC',
      country: json[0].fields['Country'] ?? null,
      submittedApplications: new Set(json[0].fields['Positions Applied For']?.split(',') ?? []),
    })
  }

  async getCountriesList() {
    const response = await fetch("https://restcountries.eu/rest/v2/all")
    const json = await response.json()

    return json 
  }
}



const parseWeeklyCapacity = (input: string) => {
  if (!input.match(/\d+/)) return null
  return parseInt(input, 10)
}

const volunteerSchema = S({
  type: 'object',
  required: [ 'id', 'createdTime', 'fields' ],
  properties: {
    id: S({ type: 'string' }),
    createdTime: S({ type: 'string', format: 'date-time' }),
    fields: S({
      type: 'object',
      properties: {
        'Timezone': S({ type: 'string' }),
        'Country': S({ type: 'string' }),
        'Weekly Capacity': S({ type: 'string' }),
        'Positions Applied For': S({ type: 'string' }),
      },
    })
  }
})

const currentVolunteerSchema = S({
  type: 'array',
  items: volunteerSchema,
  maxItems: 1,
})
