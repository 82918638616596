import React from 'react'
import { useQuery, queryCache, QueryResult, useMutation, MutateFunction, MutationResult } from 'react-query'
import { produce } from 'immer'
import { Location } from 'history'
import { Container } from 'typedi'
import Preferences from './Preferences'
import Account from './Account'
import Opportunity, { OpportunityId } from './Opportunity'
import IAnalytics, { AnalyticsEvent } from './IAnalytics'
import IAuthentication from './IAuthentication'
import IOpportunitiesRepository from './IOpportunitiesRepository'
import IPreferencesRepository from './IPreferencesRepository'
import Volunteer from './Volunteer'
import IVolunteersRepository from './IVolunteersRepository'
import {ProjectId} from './Projects'
import IProjectsRepository from './IProjectsRepository'
import ISlackChannelsRepository from './ISlackChannelsRepository'
import ISupportRepository from './ISupportRepository'
import AccessToken from './AccessToken'
import Support from './Support'




export const useLogin = (): [MutateFunction<void, void>, MutationResult<void>] => {
  const auth = Container.get(IAuthentication.token)
  const preferences = Container.get(IPreferencesRepository.token)
  const reportError = useReportError()
  const trackEvent = useTrackEvent()
  return useMutation(async () => {
    const p = await preferences.load()
    if (!p.allowCookies) throw Error('Cannot sign in without allowing cookies')
    await auth.login()
  }, {
    onMutate: () => {
      queryCache.getQuery(['accounts', 'current'])?.setData(null)
    },
    onError: (error) => {
      reportError({ error: error as Error, metadata: { mutation: 'Login' }})
    },
    onSuccess: () => {
      trackEvent({ category: 'User', action: 'Logged In' })
    },
    onSettled: async () => {
      await Promise.all([
        queryCache.refetchQueries(['accounts', 'current']),
        queryCache.refetchQueries(['volunteers', 'current']),
      ])
    },
  })
}

export const useLogout = (): [MutateFunction<void, void>, MutationResult<void>] => {
  const auth = Container.get(IAuthentication.token)
  const reportError = useReportError()
  const trackEvent = useTrackEvent()
  return useMutation(() => auth.logout(), {
    onMutate: () => {
      queryCache.getQuery(['accounts', 'current'])?.setData(null)
    },
    onError: (error) => {
      reportError({ error: error as Error, metadata: { mutation: 'Logout' } })
    },
    onSuccess: () => {
      trackEvent({ category: 'User', action: 'Logged Out' })
    },
    onSettled: async () => {
      await Promise.all([
        queryCache.refetchQueries(['accounts', 'current']),
        queryCache.refetchQueries(['volunteers', 'current']),
      ])
    },
  })
}

export const useGetProejctStages = ()  =>{
  return ([
    {
      name: "Concept",
      color: "#CFDFFF"
    },
    {
      name: "Design",
      color: "#D0F0FD"
    }, 
    {
      name: "Prototype",
      color: "#C2F5E9"
    }, 
    {
      name: "Testing",
      color: "#D1F7C4"
    }, 
    {
      name: "Documenting/Approval",
      color: "#FFDA83"
    }, 
    {
      name: "MVP",
      color: "#FEE2D5"
    }, 
  ])
}

export const useGetProjectsCategories = ()  =>{
  return ([	"Ventilator-related", "PPE","Medical Testing", "Software Application/Website", "Other" ])
}

export const useGetHelpfulHelp = (list:any)  =>{
  const helpfullSupport = ["Project Management", "QA/RA","Recruiting", "Financial/Fundraising", "Technical Expertise", "Medical Expertise", "Communications/Media" ]
  return (list?helpfullSupport.filter(x => !list.includes(x)): helpfullSupport)
}

export const useSendSupportMessage = (data: Support): QueryResult<any> =>{
  const received_data = Container.get(ISupportRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  return useQuery({
    queryKey: ['submit_support'],
    queryFn: async () => {
   
      const account = await authentication.current()
      account ? await received_data.submitSupport(data, account!.accessToken)
              : await received_data.submitSupport(data, null)

    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'supportSubmition',
          }
        })
      },
    }
  })
}

export const useGetCountriesList = (): QueryResult<any> =>{
  const user = Container.get(IVolunteersRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['get_countries'],
    queryFn: async () => {
      const response = await user.getCountriesList()
      return response
    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'get_countries',
          }
        })
      },
    }
  })
}

export const useGetVolunteerProfileData = ()  =>{
  return ({
    personal_information: {
      "id": "fh923f823hf8023f",
      "name": " Katherine McNamara", 
      "country": "America",
      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
      "avatar": "https://m.media-amazon.com/images/M/MV5BOThhZTkxMWMtY2UyYS00MTdlLTk1ZmMtZWQ0OWFkZjE2YTA1XkEyXkFqcGdeQXVyMjU0ODI4MzY@._V1_UX172_CR0,0,172,256_AL_.jpg",
      "has_edit_permission": true,
    }, social_information: {
      "email": "katherine_mcnamara@gmail.com",
      "facebook": "https://www.facebook.com/OfficialKatherineMcNamara/",
       "twitter": "https://twitter.com/Kat_McNamara", 
       "linkedin" : "https://www.linkedin.com/in/daniel-madalena-b3942b95/", 
       "slack": "@KatherineMcNamara"
    }, profession_information: {
      "title": "Web designer", 
      "industry": "Design",
      "organization": "Google", 
      "skills":["3D-Printing/Manufacturing","CAD","Software"],
      "other_skills": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }, volunteer_information: {
      "availability": 2, 
      "looking_for_opportunities": true, 
      "contact_for_management": true, 
      "newsletter": true
    }, equipment_information:{
      "equipment": ["3D printer","CNC"]
    }, projects_information:[
      {
        "id":"HE-1593678211000",
        "title": "Daniels' Project",
        "role": "Designer", 
        "highlighted_project": true,
        "members":{
          "number": 30, 
          "list":[
            {
              "id": "nçwekfnwçkenf",
              "name": "John Doe",
              "role": "Member Role",
               "avatar": "https://images-na.ssl-images-amazon.com/images/M/MV5BMTAyNTAzMTA4OTJeQTJeQWpwZ15BbWU3MDA4NDI2Njk@._V1_UX172_CR0,0,172,256_AL_.jpg", 
            },
            {
              "id": "onwenvoinwoeivnwe",
              "name": "John Doe",
              "role": "Member Role",
              "avatar": "https://images-na.ssl-images-amazon.com/images/M/MV5BMjEzMjA0ODk1OF5BMl5BanBnXkFtZTcwMTA4ODM3OQ@@._V1_UY256_CR5,0,172,256_AL_.jpg", 
            },
            {
              "id": "clwdknvonwdoivnwoie",
              "name": "John Doe",
              "role": "Member Role",
              "avatar": "https://images-na.ssl-images-amazon.com/images/M/MV5BNTk2OGU4NzktODhhOC00Nzc2LWIyNzYtOWViMjljZGFiNTMxXkEyXkFqcGdeQXVyMTE1NTQwOTk@._V1_UY256_CR12,0,172,256_AL_.jpg", 
              },
              {
                "id": "ijwebf8032023984",
                "name": "John Doe",
                "role": "Member Role",
                "avatar": "https://randomuser.me/api/portraits/men/32.jpg", 
                },
              {
                "id": "wefjp233223",
                "name": "John Doe",
                "role": "Member Role",
                "avatar": "https://images-na.ssl-images-amazon.com/images/M/MV5BNTczMzk1MjU1MV5BMl5BanBnXkFtZTcwNDk2MzAyMg@@._V1_UY256_CR2,0,172,256_AL_.jpg", 
                }, 
                {
                "id": "23423oi4n23oin4oi2n3",
                "name": "John Doe",
                "role": "Member Role",
                "avatar": "https://images-na.ssl-images-amazon.com/images/M/MV5BMjI4NjM1NDkyN15BMl5BanBnXkFtZTgwODgyNTY1MjE@._V1.._UX172_CR0,0,172,256_AL_.jpg", 
                }
          ]
        }
      }
    ]
  })
}

export const useGetUserProfileBackground = (industry: any) =>{
  let backgrounds:any = {
    "default": "https://images.pexels.com/photos/3389536/pexels-photo-3389536.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Design" : "https://images.pexels.com/photos/2832382/pexels-photo-2832382.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Agriculture, Food and Natural Resources": "https://images.pexels.com/photos/110081/pexels-photo-110081.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Architecture and Construction": "https://images.pexels.com/photos/227675/pexels-photo-227675.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Education and Training": "https://images.pexels.com/photos/590493/pexels-photo-590493.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Finance / Fundraising": "https://images.pexels.com/photos/159888/pexels-photo-159888.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Government and Public Administration": "https://www.nato.int/nato_static_fl2014/assets/pictures/2018_03_180319e-cnhq/20180319_180319e-001.jpg",
    "Health Science": "https://images.pexels.com/photos/4386467/pexels-photo-4386467.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Hospitality and Tourism": "https://images.pexels.com/photos/408503/pexels-photo-408503.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Human Resources / Operations": "https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Information Technology": "https://images.pexels.com/photos/450035/pexels-photo-450035.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Law, Public Safety, Corrections and Security": "https://images.pexels.com/photos/207662/pexels-photo-207662.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Manufacturing / Fabrication": "https://images.pexels.com/photos/3997247/pexels-photo-3997247.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Sales and Service": "https://images.pexels.com/photos/669610/pexels-photo-669610.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Science, Technology, Engineering and Mathematics": "https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Transportation, Distribution and Logistics": "https://images.pexels.com/photos/379419/pexels-photo-379419.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Marketing": "https://images.pexels.com/photos/6229/marketing-board-strategy.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Communications": "https://images.pexels.com/photos/33999/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Business Management": "https://images.pexels.com/photos/1765033/pexels-photo-1765033.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Translation": "https://images.pexels.com/photos/278887/pexels-photo-278887.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Natural Resources": "https://images.pexels.com/photos/5980/food-sunset-love-field.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Community Support": "https://images.pexels.com/photos/1000445/pexels-photo-1000445.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
    "Arts": "https://images.pexels.com/photos/3109821/pexels-photo-3109821.png?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "Audio and Video": "https://images.pexels.com/photos/2873486/pexels-photo-2873486.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  }
  return backgrounds[industry]? backgrounds[industry]: backgrounds.default
}

export const useGetIndustries = ()=>{
  return [
    "Design" ,
    "Agriculture, Food and Natural Resources",
    "Architecture and Construction",
    "Education and Training",
    "Finance / Fundraising",
    "Government and Public Administration",
    "Health Science",
    "Hospitality and Tourism",
    "Human Resources / Operations",
    "Information Technology",
    "Law, Public Safety, Corrections and Security",
    "Manufacturing / Fabrication",
    "Sales and Service",
    "Science, Technology, Engineering and Mathematics",
    "Transportation, Distribution and Logistics",
    "Marketing",
    "Communications",
    "Business Management",
    "Translation",
    "Natural Resources",
    "Community Support",
    "Arts",
    "Audio and Video"
  ]
}

export const useProjectUpdateTokenValidation = (token: AccessToken, id: ProjectId): QueryResult<any> => {
  const token_validation = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['update_token'],
    queryFn: async () => {
      let response = await token_validation.verifyToken(token, id)
      return response
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })

} 

export const useCurrentAccount = (): QueryResult<Account | null> => {
  const authentication = Container.get(IAuthentication.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['accounts', 'current'],
    queryFn: async () => authentication.current(),
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'CurrentAccount' }
        })
      }
    }
  })
}

export const useAllOpportunities = (): QueryResult<ReadonlyMap<OpportunityId, Opportunity>> => {
  const opportunities = Container.get(IOpportunitiesRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['opportunities'],
    queryFn: async () => {
      const items = await opportunities.list()
      return new Map(
        items.map(({id, value}) => [id, value]),
      )
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (opportunities) => {
        opportunities.forEach((value, id) => {
          queryCache.setQueryData(['opportunities', id], value)
        })
      },
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllOpportunities' }
        })
      },
    }
  })
}

export const useAllProjects = (): QueryResult<any> => {
  const projects = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      const items = await projects.projectsList()
      return (items)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (projects) => {
        projects.forEach((value, id) => {
          queryCache.setQueryData(['projects', id], value)
        })
      },
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })
}

export const useGetSlackChannels = (): QueryResult<any> => {
  const slack = Container.get(ISlackChannelsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['slack'],
    queryFn: async () => {
      const items = await slack.getSlackChannels()
      return (items)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (channels) => {
        channels.forEach((value, id) => {
          queryCache.setQueryData(['channels', id], value)
        })
      },
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'SlackChannels' }
        })
      },
    }
  })
}

export const useGetProjectName = (id:any): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_name'],
    queryFn: async () => {
      const items = await projects.getProjectById(id, null)
     
      if(items.length === 0){
        throw new Error('The project id you submitted is not registered')
      }
      return (items[0].value["Project Title"])
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })
}


export const useGetProjectById = (id:string): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const authentication = Container.get(IAuthentication.token)

  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_by_id'],
    queryFn: async () => {
      const account = await authentication.current()
      const item = account ? await projects.getProjectById(id, account!.accessToken)
              : await projects.getProjectById(id, null)
      if(item.length === 0){
        throw new Error('The project id you submitted is not registered')
      }
      return (item)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'ProjectByID' }
        })
      },
    }
  })
}

export const useGetProjectUpdatesById = (id:string): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const authentication = Container.get(IAuthentication.token)

  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_updates_by_id'],
    queryFn: async () => {
      const account = await authentication.current()
      const item = account ? await projects.getProjectUpdatesById(id, account!.accessToken)
              : await projects.getProjectUpdatesById(id, null)
      return (item)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'ProjectUpdatesByID' }
        })
      },
    }
  })
}


export const useCheckProjectName = (projName: string): QueryResult<Object> =>{
  const projects = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['projects_by_name'],
    queryFn: async () => {
      const items = await projects.projectsList()
      let project = items.filter(proj => proj["value"]["Project Title"] === projName)
      return (project)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'AllProjects' }
        })
      },
    }
  })
}

export const useCurrentVolunteer = (): QueryResult<Volunteer | null> => {
  const authentication = Container.get(IAuthentication.token)
  const volunteers = Container.get(IVolunteersRepository.token)
  const reportError = useReportError()
  return useQuery({
    queryKey: ['volunteers', 'current'],
    queryFn: async () => {
      const account = await authentication.current()
      if (!account) return null
      return volunteers.current(account.accessToken)
    },
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'CurrentVolunteer' }
        })
      },
    }
  })
}

export const useRegisterProject = (data: any): QueryResult<void> =>{
  const received_data = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  
  return useQuery({
    queryKey: ['project_register'],
    queryFn: async () => {
      const account = await authentication.current()
      const response = await received_data.submitProject(data, account!.accessToken)
      return response
    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'RegisterProject',
          }
        })
      },
    }
  })
}


export const useUpdateProject = (id:ProjectId, data: any): QueryResult<void> =>{
  const received_data = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  
  return useQuery({
    queryKey: ['project_update'],
    queryFn: async () => {
      const account = await authentication.current()
      const response = await received_data.updateProject(id,data, account!.accessToken)
      return response
    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'RegisterProject',
          }
        })
      },
    }
  })
}

export const useSubmitProjectUpdate = (id:ProjectId, data: any): QueryResult<void> =>{
  const received_data = Container.get(IProjectsRepository.token)
  const reportError = useReportError()
  const authentication = Container.get(IAuthentication.token)
  
  return useQuery({
    queryKey: ['submit_update'],
    queryFn: async () => {
      const account = await authentication.current()
      const response = await received_data.submitProjectUpdate(id,data, account!.accessToken)
      return response
    },
    config: { 
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      onSuccess: (res => {
        return res
      }),
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: {
            mutation: 'RegisterProject',
          }
        })
      },
    }
  })
}

export const useApplyToOpportunityAsCurrentAccount = (): [
  MutateFunction<void, OpportunityId>,
  MutationResult<void>,
] => {
  const authentication = Container.get(IAuthentication.token)
  const opportunities = Container.get(IOpportunitiesRepository.token)
  const reportError = useReportError()
  const trackEvent = useTrackEvent()
  return useMutation(async (opportunityId) => {
    const account = await authentication.current()
    if (!account) throw Error('Not authenticated')
    await opportunities.submitApplication({
      opportunityId,
      accessToken: account.accessToken,
    })
  }, {
    onSuccess: (_, opportunityId) => {
      trackEvent({
        category: 'User',
        action: 'Submitted an Application'
      })
      return queryCache.refetchQueries(['volunteers', 'current'])
    },
    onError: (error, args) => {
      reportError({
        error: error as Error,
        metadata: {
          arguments: args,
          mutation: 'ApplyToOpportunityAsCurrentAccount',
        }
      })
    }
  })
}

export const usePreferences = (): QueryResult<Preferences> => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const reportError = useReportError()

  React.useEffect(() => {
    return preferencesRepository.subscribe((preferences) => {
      queryCache.setQueryData(['preferences'], preferences)
    })
  }, [preferencesRepository])

  return useQuery({
    queryKey: ['preferences'],
    queryFn: () => preferencesRepository.load(),
    config: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true,
      onError: (error) => {
        reportError({
          error: error as Error,
          metadata: { query: 'Preferences' },
        })
      }
    }
  })
}

export const useAcceptAnalytics = (): [
  MutateFunction<void, void>,
  MutationResult<void>,
] => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const reportError = useReportError()
  return useMutation(async () => {
    const preferences = await preferencesRepository.load()
    const updated = produce(preferences, (p => {
      p.allowCookies = true
    }))
    await preferencesRepository.update(updated)
  }, {
    onError: (error) => {
      reportError({
        error: error as Error,
        metadata: { mutation: 'AcceptAnalytics' }
      })
    },
    onSettled: () => {
      return queryCache.refetchQueries(['preferences'])
    }
  })
}

export const useReportError = () => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const analytics = Container.get(IAnalytics.token)
  return React.useCallback(async (data: {
    error: Error,
    severity?: 'info' | 'warning' | 'error',
    metadata?: Record<string, any>,
  }) => {
    const preferences = await preferencesRepository.load()
    if (!preferences.allowCookies) return
    analytics.reportError(data)
  }, [preferencesRepository, analytics])
}

export const useTrackPageLoad = () => {
  const analytics = Container.get(IAnalytics.token)
  return React.useCallback(async (location: Location) => {
    analytics.trackPageChange(location)
  }, [analytics])
}

export const useTrackEvent = () => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const analytics = Container.get(IAnalytics.token)
  return React.useCallback(async (event: AnalyticsEvent) => {
    const preferences = await preferencesRepository.load()
    if (!preferences.allowCookies) return
    analytics.trackEvent(event)
  }, [preferencesRepository, analytics])
}

export const useAnalyticsErrorBoundary = (): React.ComponentType => {
  const preferencesRepository = Container.get(IPreferencesRepository.token)
  const analytics = Container.get(IAnalytics.token)

  const { data: preferences } = useQuery({
    queryKey: ['preferences'],
    queryFn: async () => preferencesRepository.load(),
  })

  const allowCookies = React.useMemo(() => {
    return preferences?.allowCookies ?? false
  }, [preferences])

  return React.useMemo(() => {
    if (allowCookies) return analytics.getErrorBoundary()
    else return React.Fragment
  }, [allowCookies, analytics])
}
