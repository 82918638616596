import { immerable } from 'immer'
import type { DataFields } from '../Core/Typelevel'
import Timestamp from '../Core/Timestamp'

export default class Opportunity {
  public static [immerable] = true

  public readonly title!: string
  public readonly description!: string
  public readonly skills!: string | null
  public readonly weeklyCommitment!: number | null
  public readonly openPositions!: number

  constructor(fields: DataFields<Opportunity>) {
    Object.assign(this, fields)
  }
}

export type OpportunityId = string

export class OpportunityMetadata {
  public static [immerable] = true

  public readonly created!: Timestamp

  constructor(fields: DataFields<OpportunityMetadata>) {
    Object.assign(this, fields)
  }
}