import { enableMapSet } from 'immer'
import React from 'react'
import ReactDOM from 'react-dom'
import { ReactQueryDevtools } from 'react-query-devtools'
import Container from 'typedi'
import Application from './Application'
import * as serviceWorker from './serviceWorker'

import Auth0 from './Infrastructure/Auth0'

import IAnalytics from './Domain/IAnalytics'
import Analytics from './Services/Analytics'
import IAuthentication from './Domain/IAuthentication'
import Authentication from './Services/Authentication'
import IOpportunitiesRepository from './Domain/IOpportunitiesRepository'
import IProjectsRepository from './Domain/IProjectsRepository'
import ISupportRepository from './Domain/ISupportRepository'
import ISlackChannelsRepository from './Domain/ISlackChannelsRepository'

import OpportunitiesRepository from './Services/OpportunitiesRepository'
import ProjectsRepository from './Services/ProjectsRepository'
import SupportRepository from './Services/SupportReposotory'
import SlackChannelsRepository from './Services/SlackChannelsRepository'

import IPreferencesRepository from './Domain/IPreferencesRepository'
import PreferencesRepository from './Services/PreferencesRepository'
import IVolunteersRepository from './Domain/IVolunteersRepository'
import VolunteersRepository from './Services/VolunteersRepository'

enableMapSet()

Container.set(Window, window)
Container.set(Storage, localStorage)
Container.set(Auth0, new Auth0(Container))
Container.set(IAnalytics.token, new Analytics(Container))
Container.set(IAuthentication.token, new Authentication(Container))
Container.set(IOpportunitiesRepository.token, new OpportunitiesRepository())
Container.set(IProjectsRepository.token, new ProjectsRepository())
Container.set(ISupportRepository.token, new SupportRepository())
Container.set(ISlackChannelsRepository.token, new SlackChannelsRepository())

const preferencesRepository = new PreferencesRepository(Container)
Container.set(IPreferencesRepository.token, preferencesRepository)
Container.set(IVolunteersRepository.token, new VolunteersRepository())

const App = () => {
  React.useEffect(() => {
    return () => {
      preferencesRepository.dispose()
    }
  })

  return (
    <>
      <Application />
      <ReactQueryDevtools />
    </>
  )
}

ReactDOM.render(
  <App  />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
