import 'typeface-work-sans'
import React from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import "./styles.sass"
import Header from './Components/Header'
import Footer from './Components/Footer'
import {
  useTrackPageLoad,
  useAnalyticsErrorBoundary,
  usePreferences,
  useAcceptAnalytics, 
  useCurrentAccount
} from './Domain/UseCases'
// import {RouteTransition, AnimatedRoutes} from "./Components/RouteTransition"
// import {MountTransition} from "./Components/MountTransition"

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Work Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#2B3852'
    },
    secondary: {
      main: '#61CE70'
    }
  },
  shape: {
    borderRadius: 4
  }
})

const OpportunityApplication = loadable(() => import('./Pages/OpportunityApplication'))
const Partners = loadable(() => import('./Pages/Partners'))
const ProjectsRegistration = loadable(() => import('./Pages/ProjectRegister/ProjectsRegistration'))
const ProjectUpdate = loadable(() => import('./Pages/ProjectUpdate/ProjectUpdate'))
const ProjectsList = loadable(() => import('./Pages/ProjectsList/ProjectsLandingPage'))
const ProjectsLandingPage = loadable(() => import('./Pages/ProjectsList/ProjectsInfo'))
const UserRegistration = loadable(() => import('./Pages/UserRegistration/UserRegistration'))
const EditUserProfile = loadable(() => import('./Pages/EditUserProfile/EditUserProfile'))
const UserProfile = loadable(() => import('./Pages/UserProfile/UserProfile'))


export default () => {
  const trackPageLoad = useTrackPageLoad()

  const { data: currentAccount} = useCurrentAccount()
 
  const history = React.useMemo(() => {
    return createBrowserHistory()
  }, [])

  
  React.useEffect(() => {
    return history.listen(()=>{
      window.scrollTo(0,0)
      return trackPageLoad
    })  
  }, [trackPageLoad, history])

  const ErrorBoundary = useAnalyticsErrorBoundary()

  const { data: preferences } = usePreferences()
  const [acceptAnalytics] = useAcceptAnalytics()
  const appBarHeight: number = 65


  return (
    <ErrorBoundary >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'auto 1fr auto',
              minHeight: '100vh',
            }}>
            <Header height={appBarHeight} />
            <main style={{marginTop: appBarHeight}}>
            <Switch>
                  <Route path="/opportunity" exact component={OpportunityApplication}/>
                  <Route path="/partners" exact component={Partners }/>
                  <Route path="/signup" exact component={UserRegistration }/>
                  <Route path="/user/:id" exact component={UserProfile }/>
                  <Route path="/user" exact render={() => <Redirect to={`/user/${currentAccount? currentAccount.slackId: ""}`} />}  />
                  <Route path="/user/edit/:id" exact component={EditUserProfile }/>
                  <Route path="/projects" exact component={ProjectsList }/>
                  <Route path="/projects/register" exact component={ProjectsRegistration }/>
                  <Route path="/projects/:id" exact component={ProjectsLandingPage }/>
                  <Route path="/projects/update/:id" exact component={ProjectUpdate }/>
                  <Route path="/*" render={() => <Redirect to="/projects" />} />
              </Switch>
              {/* <AnimatedRoutes initial={false} exitBeforeEnter>
               <Switch>
                  <Route path="/opportunity" exact render={(props)=>{
                    return <MountTransition>
                       <OpportunityApplication {...props}/>
                    </MountTransition>
                  } } />
                    
                  <Route path="/partners" exact render={()=>{
                    return <MountTransition>
                       <Partners />
                    </MountTransition>
                  } } />
                    
                  <Route path="/signup" exact render={()=>{
                    return <MountTransition>
                       <UserRegistration />
                    </MountTransition>
                  }} />
                    
                  <Route path="/user/:id" exact render={()=>{
                    return <MountTransition>
                       <UserProfile />
                    </MountTransition>
                  }} />
                    
                  <Route path="/user" exact render={() => <Redirect to={`/user/${currentAccount? currentAccount.slackId: ""}`} />}  />

                  <Route path="/user/edit/:id" exact render={()=>{
                    return <MountTransition>
                       <EditUserProfile />
                    </MountTransition>
                  }} />
                    
                  <Route path="/projects" exact render={()=>{
                    return <MountTransition>
                       <ProjectsList />
                    </MountTransition>
                  }} />
                    
                  <Route path="/projects/register" exact render={()=>{
                    return <MountTransition>
                       <ProjectsRegistration />
                    </MountTransition>
                  }} />
                    
                  <Route path="/projects/:id" exact render={()=>{
                    return <MountTransition>
                       <ProjectsLandingPage />
                    </MountTransition>
                  }} />
                    
                  <Route path="/projects/update/:id" exact render={()=>{
                    return <MountTransition>
                       <ProjectUpdate />
                    </MountTransition>
                  } }/>
                    
                  <Route path="/*" render={() => <Redirect to="/projects" />} />
              </Switch>
              </AnimatedRoutes> */}
               {/* <AnimatedRoutes initial={false} exitBeforeEnter>
                  <RouteTransition path="/opportunity" exact >
                    <OpportunityApplication />
                  </RouteTransition>
                  <RouteTransition path="/partners" exact >
                    <Partners />
                  </RouteTransition>
                  <RouteTransition path="/signup" exact >
                    <UserRegistration/>
                  </RouteTransition>
                  <RouteTransition path="/user/:id" exact >
                    <UserProfile/>
                  </RouteTransition>
                  <Route path="/user" exact render={() => <Redirect to={`/user/${currentAccount? currentAccount.slackId: ""}`} />}  />

                  <RouteTransition path="/user/edit/:id" exact >
                    <EditUserProfile/>
                  </RouteTransition>
                  <RouteTransition path="/projects" exact >
                    <ProjectsList/>
                  </RouteTransition>
                  <RouteTransition path="/projects/register" exact >
                    <ProjectsRegistration/>
                  </RouteTransition>
                  <RouteTransition path="/projects/:id" exact >
                    <ProjectsLandingPage/>
                  </RouteTransition>
                  <RouteTransition path="/projects/update/:id" exact >
                    <ProjectUpdate  />
                  </RouteTransition>
                  <Route path="/*" render={() => <Redirect to="/projects" />} />
              </AnimatedRoutes>*/}
            </main>
           
            <Footer />
          </div>
        </Router>
      
        <Snackbar
          key="cookie-banner"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={preferences?.allowCookies === null}
          autoHideDuration={null}
          message="This website uses cookies to sign you in, and to analyze site performance and usage."
          action={
            <Button
              color="secondary"
              size="small"
              onClick={() => acceptAnalytics()}>
              Accept
            </Button>
          } />
      </ThemeProvider>
    </ErrorBoundary>
  )


 
}

